
import { importSync as i } from '@embroider/macros';
let d = window.define;
d("pretty-text/allow-lister", function(){ return i("pretty-text/allow-lister");});
d("pretty-text/censored-words", function(){ return i("pretty-text/censored-words");});
d("pretty-text/emoji", function(){ return i("pretty-text/emoji");});
d("pretty-text/emoji/data", function(){ return i("pretty-text/emoji/data");});
d("pretty-text/emoji/version", function(){ return i("pretty-text/emoji/version");});
d("pretty-text/guid", function(){ return i("pretty-text/guid");});
d("pretty-text/inline-oneboxer", function(){ return i("pretty-text/inline-oneboxer");});
d("pretty-text/mentions", function(){ return i("pretty-text/mentions");});
d("pretty-text/oneboxer-cache", function(){ return i("pretty-text/oneboxer-cache");});
d("pretty-text/oneboxer", function(){ return i("pretty-text/oneboxer");});
d("pretty-text/pretty-text", function(){ return i("pretty-text/pretty-text");});
d("pretty-text/sanitizer", function(){ return i("pretty-text/sanitizer");});
d("pretty-text/text-replace", function(){ return i("pretty-text/text-replace");});
d("pretty-text/upload-short-url", function(){ return i("pretty-text/upload-short-url");});
d("pretty-text/allow-lister", function(){ return i("pretty-text/allow-lister");});
d("pretty-text/censored-words", function(){ return i("pretty-text/censored-words");});
d("pretty-text/emoji", function(){ return i("pretty-text/emoji");});
d("pretty-text/emoji/data", function(){ return i("pretty-text/emoji/data");});
d("pretty-text/emoji/version", function(){ return i("pretty-text/emoji/version");});
d("pretty-text/guid", function(){ return i("pretty-text/guid");});
d("pretty-text/inline-oneboxer", function(){ return i("pretty-text/inline-oneboxer");});
d("pretty-text/mentions", function(){ return i("pretty-text/mentions");});
d("pretty-text/oneboxer-cache", function(){ return i("pretty-text/oneboxer-cache");});
d("pretty-text/oneboxer", function(){ return i("pretty-text/oneboxer");});
d("pretty-text/pretty-text", function(){ return i("pretty-text/pretty-text");});
d("pretty-text/sanitizer", function(){ return i("pretty-text/sanitizer");});
d("pretty-text/text-replace", function(){ return i("pretty-text/text-replace");});
d("pretty-text/upload-short-url", function(){ return i("pretty-text/upload-short-url");});
import "discourse-i18n/-embroider-implicit-modules.js";
import "truth-helpers/-embroider-implicit-modules.js";
import "discourse-i18n/-embroider-implicit-modules.js";
import "ember-auto-import/-embroider-implicit-modules.js";
import "pretty-text/-embroider-implicit-modules.js";
import "pretty-text/-embroider-implicit-modules.js";
